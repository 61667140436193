import panda_main from "./animations/panda_main.json";
import Animation from "../../ui/Animation";
import Button from "../../ui/Button";
import PurpleBtn from "../../ui/Purple_button";
import style from "./style.module.scss";
import { useEffect } from "react";

const Home = ({ callback }) => {
  useEffect(() => {
    window.fbq("track", "ViewContent", {
      content_type: "Onboarding",
      content_id: 0,
      content_category: "page",
      contents: "Welcome Screen",
    });

    window.ttq.track("View Content", {
      content_type: "Onboarding",
      content_id: 0,
      content_category: "page",
      contents: "Welcome Screen",
    });
  }, []);

  return (
    <div className={`section ${style.section}`}>
      <div className={style.tooltip}>
        <svg
          width="291"
          height="170"
          viewBox="0 0 291 170"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M228.592 169.113C228.592 169.113 256.028 150.303 239.204 141.544C222.38 132.784 50.5774 150.879 22.2415 125.329C-6.09444 99.7783 -5.31626 45.7922 16.5986 26.2199C38.5135 6.64765 74.8746 0.769043 124.699 0.769043C158.1 0.769043 245.047 0.769045 268.153 26.2156C291.259 51.6621 294.184 69.6142 288.507 113.632C282.829 157.65 228.592 169.109 228.592 169.109V169.113Z"
            fill="white"
          />
        </svg>

        <div className={style.text}>Welcome to the Mio</div>
      </div>

      <div className={style.image}>
        <Animation data={panda_main} width={554} height={(440 / 320) * 425} />
      </div>

      <div className={style.actions}>
        <PurpleBtn type={[style.purple_button]} callback={() => callback({ step: 1 })}>
          Continue
        </PurpleBtn>
      </div>
    </div>
  );
};

export default Home;
