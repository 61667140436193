import React from "react";
import ReactDOM from "react-dom/client";
import Main from "./components/Main";
import "./index.css";
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import SuccessPayment from "./components/pages/successComponent/SuccessPayment";
import Buy from "./components/pages/buyComponent/Buy";

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/success_payment/:userId",
    element: <SuccessPayment />
  },
  {
    path: "/payment",
    element: <Buy/>
  }
]);
root.render(<RouterProvider router={router}></RouterProvider>);
