import { useState, useEffect } from "react";
import Home from "./screens/home/Home";
import Age from "./screens/age/Age";
import Questions from "./screens/questions/Questions";
import Success from "./screens/success/Success";
import Buy from "./pages/buyComponent/Buy";
import Login from "./screens/login/Login";
import ReactPixel from "react-facebook-pixel";
import axios from "axios";
import axiosInstance from "../axios";
import { FacebookAuthProvider, signInWithCredential } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import LegoLoader from "./screens/login/animations/LegoLoader.json";
import Animation from "./ui/Animation";
import style from "./style.module.scss";
import Edit from "./screens/edit/Edit";
import LoadingPlan from "./screens/loading/LoadingPlan";
import EmailNotification from "./screens/emailNotifications/EmailNotification";

const Main = () => {
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, null, {
    debug: true,
  });
  const [step, setStep] = useState(0);
  const [screen, setScreen] = useState("");
  const [age, setAge] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [yesCount, setYesCount] = useState(0);

  const navigate = useNavigate();
  const [_, setPaymentLinks] = useLocalStorage("payment_links", null);
  const [token, setToken] = useLocalStorage("token", null);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationEmail, setNotificationEmail] = useState('')

  const getCookieValue = (name) => {
    const nameEQ = `${name}=`;
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.indexOf(nameEQ) === 0)
        return cookie.substring(nameEQ.length, cookie.length);
    }
    return null;
  };
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get("code");
    if (code) {
      const fetchAccessToken = async () => {
        try {
          console.log({
            fbc,
            fbp,
            selectedAge,
            selectedCategories,
          });
          await axiosInstance.get("/auth/facebook/callback", {
            params: { code },
          });
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
      };

      fetchAccessToken();
    }
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("accessToken");
    if (token) {
      setIsLoading(true);
      const fetchAccessToken = async () => {
        try {
          const credential = FacebookAuthProvider.credential(token);
          const result = await signInWithCredential(auth, credential);
          const user = result.user;
          const firebaseToken = await user.getIdToken();
          const savedUserData = getCookieValue("onboardingUserData");
          const userData = JSON.parse(savedUserData);
          setToken(firebaseToken);
          const response = await axiosInstance.post(`/auth/facebook`, {
            user,
            age: userData.age,
            fbp: userData.fbp,
            fbc: userData.fbc,
            selectedCategories: userData.selectedCategories,
            notificationEmail: userData.notificationEmail
          });

          const customData = {
            success: 1,
          };

          setPaymentLinks(response.data.paymentLinks);

          if (response.data.isNewUser) {
            ReactPixel.track("CompleteRegistration", null, {
              actionSource: "browser",
            });
          }

          ReactPixel.track("TutorialCompletion", customData, {
            actionSource: "browser",
          });

          navigate("/payment");
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.error("Error fetching access token:", error);
        }
      };

      fetchAccessToken();
    }
  }, []);
  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    const trackAdImpression = async () => {
      try {
        const response = await axios.get(
          `https://track.tenjin.com/v0/impression/hqbmizVdZdELuYT9MxgnVn`
        );
        console.log("Ad impression tracked successfully:", response.data);
      } catch (error) {
        console.log(error);
      }
    };
    trackAdImpression();
  }, []);

  const set = (data) => {
    if (data?.age) setAge(data.age);

    if (data?.count) setYesCount(data.count);

    if (data?.selectedCategories)
      setSelectedCategories(data.selectedCategories);
    if (data?.notificationEmail) setNotificationEmail(data.notificationEmail)
    setStep(data.step);
  };

  useEffect(() => {
    switch (step) {
      case 0:
        setScreen(<Home callback={set} />);
        break;
      case 1:
        setScreen(<Age callback={set} />);
        break;
      case 2:
        setScreen(<Questions age={age} callback={set} />);
        break;
      case 3:
        setScreen(
          <Edit
            selectedAge={age}
            selectedCategories={selectedCategories}
            callback={set}
          />
        );
        break;
      case 4:
        setScreen(<LoadingPlan callback={set} />);
        break;
      case 5:
        setScreen(<Success age={age} count={yesCount} callback={set} />);
        break;
      case 6:
        setScreen(<EmailNotification callback={set}/>);
        break;
      case 7:
        setScreen(
          <Login
            callback={set}
            age={age}
            selectedCategories={selectedCategories}
            notificationEmail={notificationEmail}
          />
        );
        break;
      default:
        break;
    }
  }, [step, age, yesCount]);

  return isLoading ? (
    <>
      <div className={style.loaderContainer}>
        <div>
          <Animation data={LegoLoader} />
        </div>

        <div className={style.loaderText}>Loading...</div>
      </div>
    </>
  ) : (
    <div className="container">{screen}</div>
  );
};

export default Main;
