import dancingTurkey from "./animations/dancingTurkey.json";
import Animation from "../../ui/Animation";
import style from "./style.module.scss";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "firebase/auth";
import useLocalStorage from "../../../hooks/useLocalStorage";
import axiosInstance from "../../../axios";
import LegoLoader from "./animations/LegoLoader.json";

const SuccessPayment = () => {
  const { userId } = useParams();
  const [token] = useLocalStorage("token", null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = async () => {
      setLoading(true);
      try {
        if (!userId || !token) {
          navigate("/");
        }
        const config = {
          params: { userId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        await axiosInstance.get(`/auth/verify_user`, config);
        setLoading(false);
      } catch (error) {
        navigate("/");
        console.error("Error fetching data:", error);
      }
    };

    verifyUser();
  }, []);

  const handleDownload = () => {
    window.open(
      "https://apps.apple.com/us/app/mio-learning-games-for-kids/id1384886803?ppid=d9c23226-5aa5-4c0f-b4fc-c2cd54df93bf",
      "_blank"
    );
  };

  return loading ? (
    <>
      <div className={style.loaderContainer}>
        <div>
          <Animation data={LegoLoader} />
        </div>

        <div className={style.loaderText}>Loading...</div>
      </div>
    </>
  ) : (
    <div className={`section ${style.section}`}>
      <div className={style.imageContainer}>
        <div className={style.ballImage} />
        <div className={style.puzzleImage} />
        <div className={style.image}>
          <Animation data={dancingTurkey} width={250} />
        </div>
      </div>
      <div className={style.mainContent}>
        <div className={style.mainText}>
          Your subscription is<br></br> activated!
        </div>
        <div className={style.secondText}>
          Now download the app and log in via<br></br>Facebook
        </div>
        <div onClick={handleDownload} className={style.downloadImage} />
      </div>
    </div>
  );
};

export default SuccessPayment;
