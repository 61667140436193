import { useEffect, useState } from "react";
import LegoLoader from "./animations/LegoLoader.json";
import panda_parasute from "./animations/panda_parasute.json";
import Animation from "../../ui/Animation";
import PurpleBtn from "../../ui/Purple_button";
import style from "./style.module.scss";

// loader timeout
const timeout = 2000;

// onelink generate

const Success = ({ age, count, callback }) => {
  const [loading, setLoading] = useState(true);

  const send = () => {
    window.fbq("track", "Lead");

    window.ttq.track("Submit Form");

    callback({ step: 6 });
    // window.location.href = `${link.clickURL}&deep_link_value=ageid_${age}-answer_${count}`
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), timeout);
  }, [count]);

  return loading ? (
    <div className={style.loaderContainer}>
      <div>
        <Animation data={LegoLoader} />
      </div>

      <div className={style.loaderText}>Loading...</div>
    </div>
  ) : (
    <div className="section">
      <div className={style.animation}>
        <Animation data={panda_parasute} />
      </div>

      <div className={style.info}>
        <div className={style.text}>
          Congratulations! Your study plan is ready
        </div>

        <div className={style.note}>
          Enjoy the app and keep in mind that even 5 minutes of practice a day
          can help your child speak better
        </div>

        <div className={style.actions}>
          <PurpleBtn
            callback={send}
            type={[style.purple_button]}
          >
            Continue
          </PurpleBtn>
        </div>
      </div>
    </div>
  );
};

export default Success;
