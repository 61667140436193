import style from "./style.module.scss";
import panda_main from "./animations/panda_main.json";
import LegoLoader from "./animations/LegoLoader.json";
import Animation from "../../ui/Animation";
import { useState } from "react";
import useCookie, {setCookie} from "../../../hooks/useCookie";
import { isInAppBrowser } from "../../../utils/isInAppBrowser";

const Login = ({ callback, age, selectedCategories, notificationEmail }) => {
  const [loading, setLoading] = useState(false);
  const fbp = useCookie("_fbp");
  const fbc = useCookie("_fbc");

  const handleLogin = async () => {
    setLoading(true);
    const userData = {
      fbp,
      fbc,
      age,
      selectedCategories,
      notificationEmail
    }
    setCookie('onboardingUserData', JSON.stringify(userData), 1); 
    try {
      retrieveFacebookToken();
    } catch (error) {
      console.error("Error signing in with Facebook:", error);
    } finally {
      setLoading(false);
    }
  };

  const retrieveFacebookToken = async () => {
    try {
        const redirectUri = encodeURIComponent(
          `${process.env.REACT_APP_API_URL}/auth/facebook/callback`
        );
        const loginUrl = `https://www.facebook.com/v12.0/dialog/oauth?client_id=723632956416559&redirect_uri=${redirectUri}&state=YOUR_STATE_PARAM`;
        window.location.href = loginUrl;
    } catch (error) {
      console.error("Error signing in with Facebook:", error);
    }
  };

  return loading ? (
    <>
      <div className={style.loaderContainer}>
        <div>
          <Animation data={LegoLoader} />
        </div>

        <div className={style.loaderText}>Loading...</div>
      </div>
    </>
  ) : (
    <div className={style.container}>
      <div className={style.main_content}>
        <div className={style.image}>
          <Animation data={panda_main} width={554} height={(440 / 320) * 425} />
        </div>
        <div className={style.sign_in}>
          <div className={style.text}>
            To save your progress,<br></br> sign in with:
          </div>
          <div onClick={handleLogin} className={style.facebook_login} />
          <div className={style.note}>
            by continuing to log in, you accept our{" "}
            <a
              href="https://kids-education.games/termsofuse/"
              target="_blank"
              className={style.anchor}
            >
              terms of use
            </a>{" "}
            and{" "}
            <a
              href="https://kids-education.games/privacypolicy/"
              target="_blank"
              className={style.anchor}
            >
              privacy policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
