const PurpleButton = ({
    type = [],
    callback = () => null,
    children
}) => {
    return (
        <span className={`purple_button ${type.join(' ')}`} onClick={callback}>
            <div className="text">{children}</div>
        </span>
    )
}

export default PurpleButton