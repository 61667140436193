import React, { useState, useEffect } from "react";
import Animation from "../../ui/Animation";
import LoadingAnimation from "./animations/loading.json";
import Check from "../../../assets/images/check_btn.svg";

const analyzeDataText = [
  "We analyze your child`s problem",
  "We determine the interests of your child",
  "We adjust the difficulty of the levels",
  "We evaluate skills",
];

const LoadingPlan = ({ callback }) => {
  const [visibleTextCount, setVisibleTextCount] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleTextCount((prevCount) => {
        if (prevCount < analyzeDataText.length) {
          return prevCount + 1;
        } else {
          clearInterval(interval);
          return prevCount;
        }
      });
    }, 750);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 2;
        } else {
          clearInterval(interval);
          return 100;
        }
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      callback({ step: 5 });
    }, 6000);
  
    return () => clearTimeout(timeout);
  }, [callback]);

  return (
    <div style={styles.mainContent}>
      <div style={styles.animationContainer}>
        <Animation
          data={LoadingAnimation}
          width={254}
          height={(140 / 320) * 425}
        />
        <div style={styles.animationText}>
          <h4>{progress}%</h4>
        </div>
      </div>

      <div style={styles.mainText}>
        <h4>The perfect plan for your child is in the making</h4>
      </div>

      <div style={styles.analyzePlan}>
        {analyzeDataText.map((el, index) => (
          <div
            key={index}
            style={{
              ...styles.planingText,
              opacity: visibleTextCount > index ? 1 : 0,
              transition: `opacity 0.5s ease ${index * 0.3}s`,
            }}
          >
            <img src={Check} alt="check"></img>
            <p>{el}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoadingPlan;

const styles = {
  mainContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: '100%',
    width: "100%",
    flexDirection: "column",
    gap: "40px",
  },
  animationContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: '100px'
  },
  animationText: {
    position: "absolute",
    color: "#74E8FF",
    fontSize: "36px",
    textAlign: "center",
    zIndex: 1,
  },
  mainText: {
    fontSize: "24px",
    textAlign: "center",
    color: "#0072AE",
    marginTop: "120px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  analyzePlan: {
    fontSize: "16px",
    color: "#1D87A6",
    paddingTop: "10px",
    marginTop: "-50px",
  },
  planingText: {
    display: "flex",
    gap: "10px",
    opacity: 0,
    paddingTop: "10px",
  },
};