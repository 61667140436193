import panda_swimming from "./animations/panda_swimming.json";
import { useEffect, useState } from "react";
import Animation from "../../ui/Animation";
import Button from "../../ui/Button";
import style from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../../hooks/useLocalStorage";

const options = [
  {
    id: "mounthly",
    name: "1 month",
    per: "119,88$/year",
    price: "9,99 USD",
    info: ["Cancel anytime", "No ads", "New exercises every month"],
    btnText: "Start",
  },
  {
    id: "yearly",
    name: "1 year",
    old: "125$/year",
    per: "49,99$/year",
    price: "4,17 USD",
    info: [
      "Cancel anytime",
      "No ads",
      "New exercises every month",
      "7 days - Free",
    ],
    btnText: "Start a free week",
  },
];

const Buy = () => {
  const [paymentLinks, setPaymentLinks] = useLocalStorage("payment_links", null);
  const navigate = useNavigate()
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (!paymentLinks) {
      navigate("/");
    }
  }, [])

  const handleSubscription = () => {
    if (options[selected].id === "mounthly") {
      window.open(paymentLinks.mounthly, "_blank");
    }
    if (options[selected].id === "yearly") {
      window.open(paymentLinks.yearly, "_blank");
    }
  };

  return (
    <div className={`section ${style.section}`}>
      <div className={style.image}>
        <Animation data={panda_swimming} width={202} />
      </div>

      <div className={`title ${style.title}`}>
        Get unlimited access to effective learning now!
      </div>

      <div className={style.form}>
        <div className={style.options}>
          {options.map((option, index) => (
            <div
              key={index}
              className={`${style.option} ${
                index === selected ? style.selected : ""
              }`}
              onClick={() => setSelected(index)}
            >
              {!!option.old && <span className={style.label}>-60%</span>}
              <div className={style.name}>{option.name}</div>
              <div className={style.per}>
                {!!option.old && <span>{option.old}</span>}
                {option.per}
              </div>
              <div className={style.price}>{option.price}</div>
            </div>
          ))}
        </div>

        <div className={style.info}>
          <div className={style.list}>
            {options[selected].info.map((item, index) => (
              <div key={index} className={style.item}>
                {item}
              </div>
            ))}
          </div>

          <Button
            type={["blue", style.btn]}
            className={""}
            callback={handleSubscription}
          >
            {options[selected].btnText}
          </Button>
        </div>
      </div>

      <div className={style.actions}>
        <div className={style.note}>
          Automatic subscription renewal. Cancel at any time
        </div>
      </div>
    </div>
  );
};

export default Buy;