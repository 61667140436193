import React, { useState } from "react";
import Animation from "../../ui/Animation";
import Panda from './animations/panda.json';
import AppleSVG from '../../../assets/images/apple.svg';
import TractorSVG from '../../../assets/images/tractor.svg';
import PencilSVG from '../../../assets/images/pencil.svg';
import InputSVG from '../../../assets/images/input.svg';
import PurpleButton from "../../ui/Purple_button";
import './style.scss';

const EmailNotification = ({callback}) => {
  const [email, setEmail] = useState('');
  const [btnActive, setBtnActive] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setBtnActive(validateEmail(value));
  };

  const next = () => {
    if (btnActive) {
      callback({notificationEmail: email, step: 7})
    } else {
      callback({step: 7})
    }
  };

  return (
    <>
      <div className="tooltip">
        <img src={TractorSVG} alt="tractor" className="sub_images_tractor" />
        <img src={PencilSVG} alt="pencil" className="sub_images_pencil" />
        <img src={AppleSVG} alt="apple" className="sub_images_apple" />
        <svg
          width="291"
          height="170"
          viewBox="0 0 291 170"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M228.592 169.113C228.592 169.113 256.028 150.303 239.204 141.544C222.38 132.784 50.5774 150.879 22.2415 125.329C-6.09444 99.7783 -5.31626 45.7922 16.5986 26.2199C38.5135 6.64765 74.8746 0.769043 124.699 0.769043C158.1 0.769043 245.047 0.769045 268.153 26.2156C291.259 51.6621 294.184 69.6142 288.507 113.632C282.829 157.65 228.592 169.109 228.592 169.109V169.113Z"
            fill="white"
          />
        </svg>

        <div className="email_notification_text">Bonus! <br /> Get coloring pages</div>
      </div>
      <div className='image'>
        <Animation data={Panda} width={204} height={(90 / 320) * 425} />
      </div>
      <div className="input-container">
        <img src={InputSVG} alt="input background" className="input-svg-bg" />
        <input 
          type="email" 
          placeholder="Enter your email" 
          value={email} 
          onChange={handleEmailChange}
          className="emailInput" 
        />
      </div>
      <div className='actions'>
        <PurpleButton type={[!btnActive ? "disabled" : ""]} callback={next}>
          Continue
        </PurpleButton>

        <div className='skip' onClick={next}>
          skip
        </div>
      </div>
    </>
  );
};

export default EmailNotification;