import React, { useState } from "react";
import ArrowDownButton from "../../../assets/images/button_arrow.svg";
import CheckedIcon from "../../../assets/images/checked_icon.svg";
import PurpleBtn from "../../ui/Purple_button";

const Edit = ({ selectedAge, selectedCategories, callback }) => {
  const initialCategories = [
    {
      id: "puzzles",
      title: "Puzzles",
      checked: selectedCategories.includes("puzzles"),
    },
    {
      id: "articulation_exercises",
      title: "Articulation Exercises",
      checked: selectedCategories.includes("articulation_exercises"),
    },
    {
      id: "learning_sound",
      title: "Learn The Sound",
      checked: selectedCategories.includes("learning_sound"),
    },
    {
      id: "learning_word",
      title: "Learn Words",
      checked: selectedCategories.includes("learning_word"),
    },
    {
      id: "learn_to_count",
      title: "Learn to Count",
      checked: selectedCategories.includes("learn_to_count"),
    },
    {
      id: "tongue_twisters",
      title: "Tongue Twisters",
      checked: selectedCategories.includes("tongue_twisters"),
    },
    {
      id: "repeat_words",
      title: "Repeat Letters",
      checked: selectedCategories.includes("repeat_words"),
    },
  ];

  const [isAgeContentOpen, setIsAgeContentOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(initialCategories);
  const [updatedAge, setUpdatedAge] = useState(selectedAge);

  const toggleContent = () => {
    setIsAgeContentOpen(!isAgeContentOpen);
  };

  const handleToggleCategory = (id) => {
    setSelectedItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const handleCreatePlan = () => {
    const selectedCategories = selectedItems.filter((item) => item.checked);
    callback({
      step: 4,
      age: updatedAge,
      selectedCategories: selectedCategories,
    });
  };

  const showAge = () => {
    if (updatedAge === 1) return "0-2 Years";
    if (updatedAge === 2) return "2-4 Years";
    if (updatedAge === 3) return "4-6 Years";
    if (updatedAge === 4) return "6-8 Years";
    if (updatedAge === 5) return "8+ Years";
    return "Not specified";
  };

  return (
    <div style={styles.container}>
      <div style={styles.accordionHeader} onClick={toggleContent}>
        <h3 style={styles.title}>{showAge()}</h3>
        <span
          style={{
            ...styles.arrow,
            transform: isAgeContentOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <img alt="arrow_button" src={ArrowDownButton} />
        </span>
      </div>
      <div
        style={{
          ...styles.ageContent,
          minHeight: isAgeContentOpen ? "330px" : "0px",
          maxHeight: isAgeContentOpen ? "330px" : "0px",
          opacity: isAgeContentOpen ? 1 : 0,
          transition: "max-height 0.5s ease, opacity 0.5s ease",
          position: "absolute",
          top: "90px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 2,
          backgroundColor: "#eef6ff",
          overflowY: "auto",
          width: "90%",
          padding: "15px",
        }}
      >
        <div style={styles.ageSelection}>
          {[
            { id: 1, label: "0-2 Years" },
            { id: 2, label: "2-4 Years" },
            { id: 3, label: "4-6 Years" },
            { id: 4, label: "6-8 Years" },
            { id: 5, label: "8+ Years" },
            { id: 0, label: "Do not specify" },
          ].map((age) => (
            <label key={age.id} style={styles.radioLabel} onClick={() => setUpdatedAge(age.id)}>
              <span
                style={styles.customCheckbox(updatedAge === age.id)}
              >
                {updatedAge === age.id && (
                  <img
                    alt="checked_icon"
                    src={CheckedIcon}
                    style={styles.checkedIcon}
                  />
                )}
              </span>
              {age.label}
            </label>
          ))}
        </div>
      </div>
      <div
        style={{
          ...styles.accordionContent,
          maxHeight: "350px",
          overflowY: "auto",
          marginTop: "20px",
          position: "relative",
          zIndex: 1,
        }}
      >
        {selectedItems.map((item) => (
          <label
            style={styles.radioLabel}
            key={item.id}
            onClick={() => handleToggleCategory(item.id)}
          >
            <span style={styles.customCheckbox(item.checked)}>
              {item.checked && (
                <img
                  alt="checked_icon"
                  src={CheckedIcon}
                  style={styles.checkedIcon}
                />
              )}
            </span>
            {item.title}
          </label>
        ))}
      </div>
      <div style={styles.actions}>
        <PurpleBtn type={[styles.purple_button]} callback={handleCreatePlan}>
          Create a plan
        </PurpleBtn>
      </div>
    </div>
  );
};

const styles = {
  container: {
    marginTop: "50px",
    borderRadius: "8px",
    width: "350px",
    padding: "20px",
    position: "relative",
  },
  accordionHeader: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    padding: "10px 0",
    backgroundColor: "#eef6ff",
    borderRadius: "10px",
    textAlign: "center",
    fontSize: "18px",
    color: "#1976d2",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  title: {
    margin: 0,
  },
  arrow: {
    fontSize: "18px",
    transition: "transform 0.3s ease",
  },
  accordionContent: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    backgroundColor: "#eef6ff",
    borderRadius: "10px",
    padding: "15px",
    textAlign: "center",
    overflowY: "auto",
    position: "relative",
    maxHeight: '350px',
    zIndex: 1,
  },
  ageContent: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    backgroundColor: "#eef6ff",
    borderRadius: "10px",
    padding: "15px",
    position: "absolute",
    top: "50px",
    zIndex: 2,
    width: "90%",
    overflowY: "auto",
  },
  ageSelection: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    padding: "10px",
    borderRadius: "10px",
    textAlign: "left",
  },
  radioLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    cursor: "pointer",
    fontSize: "18px",
    color: "#1976d2",
    padding: "5px 0",
    borderRadius: "5px",
    width: "100%",
  },
  customCheckbox: (checked) => ({
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    border: checked ? "none" : "2px solid #b39ddb",
    display: "inline-block",
    cursor: "pointer",
    position: "relative",
    backgroundColor: checked ? "transparent" : "none",
  }),
  checkedIcon: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "50%",
  },
  actions: {
    marginTop: "100px",
    display: "flex",
    justifyContent: "center",
  },
};

export default Edit;